import { PreDepartureCertificateQuery_preDepartureCertificate } from 'pages/__generated__/PreDepartureCertificateQuery'
import ReactMarkdown from 'react-markdown'
import remarkExternalLinks from 'remark-external-links'
import remarkSmartypants from 'remark-smartypants'
import arrivalPcrInconclusive from './ArrivalPcrInconclusive.md'
import arrivalPcrNegative from './ArrivalPcrNegative.md'
import arrivalPcrPositive from './ArrivalPcrPositive.md'
import outboundLfdInconclusive from './OutboundLfdInconclusive.md'
import outboundLfdNegative from './OutboundLfdNegative.md'
import outboundLfdPositive from './OutboundLfdPositive.md'
import outboundPcrInconclusive from './OutboundPcrInconclusive.md'
import outboundPcrNegative from './OutboundPcrNegative.md'
import outboundPcrPositive from './OutboundPcrPositive.md'
import scovInconclusive from './ScovInconclusive.md'
import scovNegative from './ScovNegative.md'
import scovPositive from './ScovPositive.md'
import tcovInconclusive from './TcovInconclusive.md'
import tcovNegative from './TcovNegative.md'
import tcovPositive from './TcovPositive.md'

const getMarkdown = (certificate: PreDepartureCertificateQuery_preDepartureCertificate) => {
  const { sku, result, testPurpose } = certificate

  if (sku === 'scov') {
    switch (result) {
      case 'NEGATIVE':
        return scovNegative
      case 'POSITIVE':
        return scovPositive
      case 'INCONCLUSIVE':
        return scovInconclusive
      default:
        throw new Error(`Can't handle SCOV result ${result}`)
    }
  }

  if (sku === 'tcov') {
    switch (result) {
      case 'NEGATIVE':
        return tcovNegative
      case 'POSITIVE':
        return tcovPositive
      case 'INCONCLUSIVE':
        return tcovInconclusive
      default:
        throw new Error(`Can't handle TCOV result ${result}`)
    }
  }

  if (['rapid_travel', 'rapid_travel_hughes', 'rapid_ag'].includes(sku)) {
    switch (result) {
      case 'NEGATIVE':
        return outboundLfdNegative
      case 'POSITIVE':
        return outboundLfdPositive
      case 'INCONCLUSIVE':
        return outboundLfdInconclusive
      default:
        throw new Error(`Can't handle Arrival LFD result ${result}`)
    }
  }

  if (['salient_pcr', 'tdl_pcr', 'ncov', 'day2', 'day2and8', 'day8', 'ncov_release'].includes(sku)) {
    if (['COV2', 'COV8'].includes(testPurpose)) {
      switch (result) {
        case 'NEGATIVE':
          return arrivalPcrNegative
        case 'POSITIVE':
          return arrivalPcrPositive
        case 'INCONCLUSIVE':
          return arrivalPcrInconclusive
        default:
          throw new Error(`Can't handle Arrival PCR result ${result}`)
      }
    }

    switch (result) {
      case 'NEGATIVE':
        return outboundPcrNegative
      case 'POSITIVE':
        return outboundPcrPositive
      case 'INCONCLUSIVE':
        return outboundPcrInconclusive
      default:
        throw new Error(`Can't handle Outbound PCR result ${result}`)
    }
  }

  throw new Error(`Can't handle SKU ${sku} result ${result}`)
}

interface NextStepsProps {
  certificate: PreDepartureCertificateQuery_preDepartureCertificate
}

const NextSteps = ({ certificate }: NextStepsProps) => {
  return (
    <div className="content">
      <ReactMarkdown remarkPlugins={[remarkExternalLinks, remarkSmartypants]}>{getMarkdown(certificate)}</ReactMarkdown>
    </div>
  )
}

export default NextSteps
