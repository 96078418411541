import { gql, useApolloClient, useQuery } from '@apollo/client'
import cx from 'classnames'
import { BulmaMessage } from 'components/Bulma'
import Loader from 'components/Loader'
import NextSteps from 'components/Results/NextSteps'
import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Link } from 'gatsby'
import b64toBlob from 'helpers/b64ToBlob'
import rollbar from 'helpers/rollbar'
import { useEffect, useState } from 'react'
import { FaFilePdf } from 'react-icons/fa'
import { StringParam, useQueryParam } from 'use-query-params'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import SubmitButton from '../components/SubmitButton'
import TextInput from '../components/TextInput'
import { validationString } from '../components/Dashboard/RecoveryCertificate/Fields'
import { CertificateByKey, CertificateByKeyVariables } from './__generated__/CertificateByKey'
import { CertificatePdfMutation, CertificatePdfMutationVariables } from './__generated__/CertificatePdfMutation'
import {
  PreDepartureCertificateMutation,
  PreDepartureCertificateMutation_preDepartureCertificate,
  PreDepartureCertificateMutationVariables,
} from './__generated__/PreDepartureCertificateMutation'

const certificateByKey = gql`
  query CertificateByKey($secretKey: ID!) {
    certificateByKey(secretKey: $secretKey)
  }
`

const preDepartureCertificateMutation = gql`
  mutation PreDepartureCertificateMutation($input: PreDepartureCertificateInput!) {
    preDepartureCertificate(input: $input) {
      kitId
      name
      dob
      passportNumber
      labNo
      labId
      sampleDate
      resultDate
      result
      sku
      hasPdf
      bookingRef
      testPurpose
      photo {
        id
        filename
        mimeType
        base64
      }
    }
  }
`

const certificatePdfMutation = gql`
  mutation CertificatePdfMutation($input: PreDepartureCertificateInput!) {
    preDepartureCertificate(input: $input) {
      kitId
      name
      pdf {
        id
        base64
        mimeType
      }
    }
  }
`

const ErrorNotice = ({ children }) => (
  <section className="section">
    <p className="subtitle has-text-midnightBlue">{children}</p>
  </section>
)

const friendlyKitId = (id: string) => `${id.slice(0, 4)}-${id.slice(4)}`

const resultParser = (result: string, isAntibody: boolean) => {
  if (isAntibody) {
    if (result === 'POSITIVE') return 'POSITIVE - SARS-CoV-2 antibodies detected'
    if (result === 'NEGATIVE') return 'NEGATIVE - SARS-CoV-2 antibodies NOT detected'
  } else {
    if (result === 'POSITIVE') return 'POSITIVE - SARS-CoV-2 virus detected'
    if (result === 'NEGATIVE') return 'NEGATIVE - SARS-CoV-2 virus NOT detected'
  }
  return result
}

const Definition = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <p className="mt-1">
    <strong>{title}: </strong>
    {children}
  </p>
)

const PdfDownloadButton = ({
  className,
  secretKey,
  kitId,
  dateOfBirth,
}: {
  className?: string
  secretKey: string
  kitId: string
  dateOfBirth: string
}) => {
  const client = useApolloClient()
  const [downloadError, setDownloadError] = useState(false)
  const [loading, setLoading] = useState(false)

  const onDownloadPdf = async () => {
    try {
      setLoading(true)
      setDownloadError(false)

      const response = await client.mutate<CertificatePdfMutation, CertificatePdfMutationVariables>({
        mutation: certificatePdfMutation,
        variables: {
          input: { secretKey, dateOfBirth, downloaded: true },
        },
      })

      const certificate = response.data.preDepartureCertificate
      if (!certificate) {
        throw new Error('Unexpected response')
      }

      const filename = `${certificate.name} - ${friendlyKitId(certificate.kitId)} - Certificate.pdf`

      const blob = b64toBlob(certificate.pdf.base64, certificate.pdf.mimeType)
      const a = document.createElement('a')
      const url = URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.onclick = () => {
        setTimeout(() => URL.revokeObjectURL(url), 100)
      }
      a.click()
    } catch (err) {
      rollbar.error('Error downloading cert PDF', err, {
        kitId,
      })
      setDownloadError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <button
        type="button"
        className={cx(
          'button is-midnightBlue',
          {
            'is-loading': loading,
          },
          className
        )}
        onClick={onDownloadPdf}
      >
        <FaFilePdf className="mr-3" />
        Download PDF Certificate
      </button>

      {downloadError && (
        <ErrorNotice>
          Something went wrong downloading your certificate, we've been notified. Please try again later or{' '}
          <Link to="/contact">contact our support team</Link>.
        </ErrorNotice>
      )}
    </>
  )
}
const Result = () => {
  const [key] = useQueryParam('key', StringParam)
  const [dob] = useQueryParam('dob', StringParam)
  const [dateOfBirth, setDateOfBirth] = useState<string>(dob)
  const { loading, error, data } = useQuery<CertificateByKey, CertificateByKeyVariables>(certificateByKey, {
    variables: { secretKey: key },
  })

  const client = useApolloClient()
  const [preDepartureCertificateDetails, setPreDepartureCertificateDetails] = useState<{
    preDepartureCertificate: PreDepartureCertificateMutation_preDepartureCertificate
    dateOfBirth: string
  }>()
  const [errorMessage, setErrorMessage] = useState<JSX.Element>()

  const validationSchema = yup.object().shape({
    dateOfBirth: validationString(
      'Date of Birth',
      /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
      'Must be in the format dd/mm/yyyy e.g. 01/01/1980'
    ),
  })

  useEffect(() => {
    if (dateOfBirth && key) {
      findOrder({ key, dateOfBirth })
    }
  }, [dateOfBirth, key])

  const findOrder = async ({ key, dateOfBirth }) => {
    const response = await client.mutate<PreDepartureCertificateMutation, PreDepartureCertificateMutationVariables>({
      mutation: preDepartureCertificateMutation,
      variables: {
        input: {
          secretKey: key,
          dateOfBirth: dateOfBirth,
          downloaded: false,
        },
      },
    })

    if (response.data?.preDepartureCertificate) {
      setPreDepartureCertificateDetails({
        preDepartureCertificate: response.data.preDepartureCertificate,
        dateOfBirth: dateOfBirth,
      })
    } else {
      setErrorMessage(<p>The details provided did not match our records, please check and try again</p>)
    }
  }

  const onSubmit = async (data) => {
    setErrorMessage(undefined)
    setDateOfBirth(data.dateOfBirth.split('/').reverse().join('-'))
    return true
  }

  if (loading) return <Loader />

  if (!key) return <ErrorNotice>No key specified</ErrorNotice>

  if (error)
    return (
      <ErrorNotice>
        Something went wrong, we've been notified. Please try again later or{' '}
        <Link to="/contact">contact our support team</Link>.
      </ErrorNotice>
    )

  const certificate = data?.certificateByKey

  if (!certificate) {
    return <ErrorNotice>No result found, the URL might be mistyped or the certificate could have expired</ErrorNotice>
  }

  return (
    <>
      {errorMessage && <BulmaMessage color="danger">{errorMessage}</BulmaMessage>}

      {preDepartureCertificateDetails ? (
        <ResultDetails preDepartureCertificateDetails={preDepartureCertificateDetails} secretKey={key} />
      ) : (
        <section className="section">
          <div className="container">
            <h1 className="title has-text-midnightBlue is-size-4-mobile has-text-centered">View Certificate</h1>
            <div className="has-width-large margin-auto box mt-6">
              <h2 className="subtitle mb-5 has-text-midnightBlue">
                Please enter your Date of Birth to view your certificate
              </h2>
              <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{}}>
                {(formik) => (
                  <Form>
                    <div className="mb-5">
                      <TextInput
                        name="dateOfBirth"
                        label="Date of Birth"
                        placeholder="01/01/1980"
                        className="is-fullwidth"
                      />
                    </div>
                    <div className="has-text-centered">
                      <SubmitButton
                        submitting={formik.isSubmitting}
                        className="button is-rounded is-midnightBlue is-centered"
                        key="submit"
                      >
                        View Certificate
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

const ResultDetails = ({ preDepartureCertificateDetails, secretKey }) => {
  const { preDepartureCertificate: certificate, dateOfBirth } = preDepartureCertificateDetails

  const { sku } = certificate

  const isAntigen = ['rapid_travel', 'rapid_travel_hughes', 'rapid_ag'].includes(sku)
  const isPCR = ['salient_pcr', 'tdl_pcr', 'salient_pcr', 'ncov', 'day2', 'day2and8', 'day8', 'ncov_release'].includes(
    sku
  )
  const isAntibody = ['scov', 'tcov'].includes(sku)

  return (
    <>
      <section className="section is-small">
        <div className="container is-max-desktop">
          <h1 className="title has-text-midnightBlue has-text-centered is-family-primary has-text-weight-normal is-size-4-mobile">
            {isAntigen && 'Certificate of Coronavirus (SARS-CoV-2) Rapid Antigen Testing'}
            {isPCR && 'Certificate of Coronavirus (COVID-19) rtPCR Testing'}
            {isAntibody && 'Certificate of Coronavirus (COVID-19) Antibody Testing'}
          </h1>

          <div className="has-text-centered is-size-4 has-text-weight-normal">
            {resultParser(certificate.result, isAntibody)}
            {isAntigen && (
              <p className="is-size-6 has-text-weight-light">
                Test cassette interpreted and reported by trained technician.
              </p>
            )}
            <br />

            {certificate.hasPdf === true ? (
              <PdfDownloadButton
                className="my-3"
                kitId={certificate.kitId}
                secretKey={secretKey}
                dateOfBirth={dateOfBirth}
              />
            ) : (
              <BulmaMessage className="my-3" color="info">
                There is no certificate available for your result. Only POSITIVE and NEGATIVE results are eligible for a
                certificate.{' '}
              </BulmaMessage>
            )}
          </div>

          <h2 className="title mt-4 is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
            Testee Details
          </h2>

          <Definition title="Name">{certificate.name}</Definition>
          <Definition title="Date of birth">{format(parseISO(certificate.dob), 'dd/MM/yy')}</Definition>
          <Definition title="Passport / ID number">
            {certificate.passportNumber || 'Not provided'}{' '}
            {isAntigen && certificate.passportNumber && (
              <em className="has-text-weight-light">(Identity document verified)</em>
            )}
          </Definition>
          {certificate.bookingRef && (
            <Definition title="Day 2 Test Booking Reference Number">{certificate.bookingRef}</Definition>
          )}

          <hr />

          <h2 className="title mt-4 is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">Next Steps</h2>

          <NextSteps certificate={certificate} />

          <hr />

          <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">Test Details</h2>

          <Definition title="Order ID">{friendlyKitId(certificate.kitId)}</Definition>

          <Definition title="Sample date">
            {certificate.sampleDate
              ? format(utcToZonedTime(parseISO(certificate.sampleDate), 'Europe/London'), 'dd/MM/yy HH:mm')
              : 'Not known'}
            {certificate.sampleDate && ' (London time)'}
          </Definition>
          <Definition title="Result date">
            {format(utcToZonedTime(parseISO(certificate.resultDate), 'Europe/London'), 'dd/MM/yy HH:mm')} (London time)
          </Definition>
          <Definition title="Test type">
            {isAntigen ? 'SARS-CoV-2 Rapid Antigen Lateral Flow Test' : 'SARS-CoV-2 rtPCR'}
          </Definition>

          {isAntigen && (
            <>
              <Definition title="Test Name">
                {sku === 'rapid_travel' && 'Coronavirus Ag Rapid Test Cassette (Swab), GCOV-502a'}
                {sku === 'rapid_travel_hughes' && 'SARS-CoV-2 Antigen Flowflex Rapid Test'}
              </Definition>
              <Definition title="Test Manufacturer">
                {sku === 'rapid_travel' && 'Healgen / Orient Gene Biotech'}
                {sku === 'rapid_travel_hughes' && 'Hughes / ACON Biotech'}
              </Definition>
              <Definition title="Test Description">
                Rapid immunochromatographic assay for the detection of the SARS-CoV-2 nucleocapsid protein by nasal
                swab.
              </Definition>
              <Definition title="Test Sensitivity">97% (≥80% at viral loads ≥100,000 copies/ml)</Definition>
              <Definition title="Test Specificity">99%</Definition>
              <Definition title="Test Authorisation">
                <a
                  href="https://www.gov.uk/government/publications/assessment-and-procurement-of-coronavirus-covid-19-tests/outcome-of-the-evaluation-of-rapid-diagnostic-assays-for-specific-sars-cov-2-antigens-lateral-flow-devices"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Passed phase 3 (final) assessment by Public Health England
                </a>
                , CE-marked IVD in accordance with Directive 98/79/EC
              </Definition>
            </>
          )}

          {!isAntigen && (
            <Definition title="ISO standard">
              ISO {certificate.labId === 'SALIENT' ? '17025' : '15189'} compliant
            </Definition>
          )}

          <Definition title="Result">{resultParser(certificate.result)}</Definition>

          {certificate.photo && (
            <>
              <hr />

              <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
                Uploaded Photo
              </h2>

              <img src={certificate.photo.base64} style={{ maxWidth: 480 }} alt="Passport and test cassette" />
            </>
          )}

          {certificate.labId === 'SELF_HOME' && (
            <>
              <hr />

              <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
                Approved Testing Provider
              </h2>

              <p className="my-3">
                C19 Testing | Selph is a{' '}
                <a
                  href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1083315/covid-private-testing-providers-general-testing-16-6-22.csv/preview"
                  target="_blank"
                  rel="noreferrer"
                >
                  UK government-approved
                </a>{' '}
                provider of coronavirus testing.
              </p>

              <address className="is-size-6 mb-3">
                C19 Testing (
                <a href="https://www.c19testing.co.uk" target="_blank" rel="noopener noreferrer">
                  www.c19testing.co.uk
                </a>
                )
                <br />
                124 City Road
                <br />
                London
                <br />
                EC1V 2NX
              </address>
            </>
          )}

          {certificate.labId === 'SALIENT' && (
            <>
              <hr />

              <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
                Certified Testing Laboratory
              </h2>

              <address className="is-size-6 mb-3">
                Salient Bio Laboratory (
                <a href="https://salient.bio/" target="_blank" rel="noopener noreferrer">
                  salient.bio
                </a>
                )<br />
                Unit 506, Cocoa Studios
                <br />
                The Biscuit Factory
                <br />
                100 Drummond Road
                <br />
                London
                <br />
                SE16 4DG
                <br />
              </address>

              <a
                href="https://www.ukas.com/wp-content/uploads/schedule_uploads/971860/22223-Testing-Single.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Confirmation of UKAS Accreditation
              </a>
            </>
          )}

          {certificate.labId === 'BIOGRAD' && (
            <>
              <hr />

              <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
                Certified Testing Laboratory
              </h2>

              <address className="is-size-6 mb-3">
                Biograd Laboratory (
                <a href="https://www.biograd.co.uk" target="_blank" rel="noopener noreferrer">
                  www.biograd.co.uk
                </a>
                )
                <br />
                Liverpool Science Park
                <br />
                Mount Pleasant
                <br />
                Liverpool
                <br />
                L3 5TF
                <br />
                Tel: +44(0)151 482 9699
              </address>

              <a
                href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/959903/covid-private-testing-providers-general-testing-100221.csv/preview"
                target="_blank"
                rel="noopener noreferrer"
              >
                Approved UK Provider of Coronavirus Testing
              </a>
            </>
          )}

          {certificate.labId === 'TDL' && (
            <>
              <hr />

              <h2 className="title is-size-4 is-size-5-mobile is-family-primary has-text-weight-normal">
                Certified Testing Laboratory
              </h2>

              <address className="is-size-6 mb-3">
                The Doctors Laboratory (
                <a href="https://www.tdlpathology.com" target="_blank" rel="noopener noreferrer">
                  www.tdlpathology.com
                </a>
                )<br />
                The Halo Building
                <br />
                1 Mabledon Place
                <br />
                London
                <br />
                WC1H 9AX
                <br />
                Tel: 020 7307 7373
                <br />
              </address>

              <a
                href="https://search.ukas.com/#/tabbed/search?q=the%20doctors%20laboratory&ati=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Confirmation of UKAS Accreditation
              </a>
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default Result
